@import url(https://use.typekit.net/wfj2xgj.css);
html,
body {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 100%;
  margin: 0;
  padding: 0;
}
#root {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overflow-y: visible;
}
img {
  border: none;
  outline: none;
}
a img {
  width: 100%;
  height: auto;
  border: none;
  outline: none;
}
h1 {
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 300;
  font-size: normal;
  font-display: swap;
  font-size: 3rem;
  letter-spacing: -0.1rem;
}
h2 {
  font-size: 3em;
  margin: 0;
  padding: 0;
  display: block;
}
h3 {
  font-size: 1.4em;
  margin: 0;
  padding: 0;
  display: block;
}
p {
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 300;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  line-height: 1.5em;
  margin: 0;
}
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0.4em;
}
ul li {
  font-size: 1em;
  line-height: 1.5em;
}
*:focus {
  outline: none;
}
.divider {
  width: 25%;
  max-width: 45px;
  height: 5px;
  border: none;
}
.divider--blue {
  background: url(/app/themes/upmc-na/static/media/dots_blue.8ea5a773.svg) center center repeat-x;
}
.divider--grey {
  background: url(/app/themes/upmc-na/static/media/dots_grey.e2cec4f6.svg) center center repeat-x;
}
/* Structure */
.view {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: padding 550ms cubic-bezier(0, 0.615, 0.39, 1);
}
.view--internal {
  background: url(/app/themes/upmc-na/static/media/background.c28f0785.jpg) center center no-repeat;
  background-size: cover;
}
.view--hasHeader {
  padding-top: 90px;
}
.view--hasBreadcrumbs {
  padding-bottom: 84px;
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .view {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .view {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
  }
}
@media only screen and (device-width: 414px) and (device-height: 869px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .view {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
  }
}
@media only screen and (orientation: landscape) {
  .view--hasHeader {
    padding-top: 70px;
  }
  .view--hasBreadcrumbs {
    padding-bottom: 65px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .view--hasHeader {
    padding-top: 70px;
  }
  .view--hasBreadcrumbs {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 500px) {
  .view--hasHeader {
    padding-top: 70px;
  }
  .view--hasBreadcrumbs {
    padding-bottom: 0;
  }
}
/* Route Transitiions */
.routeTransition-enter {
  -webkit-opacity: 0;
  opacity: 0;
}
.routeTransition-enter-active {
  -webkit-opacity: 1;
  opacity: 1;
  transition: opacity 550ms cubic-bezier(0, 0.615, 0.39, 1);
}
.routeTransition-exit {
  -webkit-opacity: 1;
  opacity: 1;
}
.routeTransition-exit-active {
  -webkit-opacity: 0;
  opacity: 0;
  transition: opacity 550ms cubic-bezier(0, 0.615, 0.39, 1);
}
/* Activity Indicator Modal */
.activity {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(190, 180, 227, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: opacity 250ms cubic-bezier(0, 0.615, 0.39, 1), visibility 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.activity--show {
  -webkit-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.activity--show .activity__spinner {
  -webkit-animation: activitySpin 500ms linear infinite;
  animation: activitySpin 500ms linear infinite;
}
.activity--hide {
  -webkit-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.activity__spinner {
  width: 100px;
  height: 100px;
  background: url(/app/themes/upmc-na/static/media/spinner.2c58915f.svg) center center no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@-webkit-keyframes activitySpin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes activitySpin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
form {
  margin: 0;
  padding: 0;
}
fieldset {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}
/* Textfields/Textareas */
.textField {
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 60px;
  width: 100%;
  padding: 0 15px 0 50px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.textField:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
input[type=text] {
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 60px;
  width: 100%;
  padding: 0 15px 0 50px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
input[type=text]:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
input[type=text].username {
  background-image: url(/app/themes/upmc-na/static/media/icon_user.158d5718.svg);
  background-position: 20px center;
  background-repeat: no-repeat;
  background-size: 20px auto;
}
input[type=text].username.valid {
  background-image: url(/app/themes/upmc-na/static/media/icon_user.158d5718.svg), url(/app/themes/upmc-na/static/media/icon_valid.9602816d.svg);
  background-position: 20px center, 97% center;
  background-repeat: no-repeat;
  background-size: 20px auto;
}
input[type=password] {
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 60px;
  width: 100%;
  padding: 0 15px 0 50px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
  background-image: url(/app/themes/upmc-na/static/media/icon_lock.42f98422.svg);
  background-position: 20px center;
  background-repeat: no-repeat;
  background-size: 20px auto;
}
input[type=password]:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
.textArea {
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 200px;
  width: 100%;
  padding: 20px 20px 10px 60px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  resize: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.textArea:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
textarea {
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 200px;
  width: 100%;
  padding: 20px 20px 10px 60px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  resize: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
textarea:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
/* Buttons */
.btn {
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1em;
  box-sizing: border-box;
  display: block;
  background-image: linear-gradient(180deg, #3CE2F2 0%, #1CC1E0 100%);
  -ms-box-shadow: 0 10px 25px 0 #51284F;
  box-shadow: 0 10px 25px 0 #51284F;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  margin: 0 auto;
}
.btn:hover,
.btn:focus {
  outline: none;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -ms-box-shadow: 0 0 0 0 #51284F;
  box-shadow: 0 0 0 0 #51284F;
}
.btn--regular {
  padding: 20px 55px;
}
.btn--small {
  padding: 20px !important;
}
.btn--large {
  display: block;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 700;
  font-size: normal;
  font-display: swap;
  font-size: 1.75rem;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  padding: 15px 2% 35px 2%;
  height: 100px;
  width: 100%;
  max-width: 350px;
  background-image: linear-gradient(180deg, #3CE2F2 0%, #1CC1E0 100%);
  border-radius: 20px;
  -ms-box-shadow: 0 4px 8px 0 #51284F;
  box-shadow: 0 4px 8px 0 #51284F;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 45px;
  margin: auto;
}
.btn--large:hover,
.btn--large:focus,
.btn--large:active {
  outline: none;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -ms-box-shadow: 0 0 0 0 #51284F;
  box-shadow: 0 0 0 0 #51284F;
}
.btn--large-hide {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
@media screen and (max-width: 667px) {
  .btn--large {
    font-size: 1.5rem;
    padding: 10px 2% 20px 2%;
    max-width: 350px;
    height: 80px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .btn--large {
    bottom: -20px;
  }
}
@media only screen and (max-width: 500px) {
  .btn--large {
    bottom: -20px;
  }
}
button {
  cursor: pointer;
}
.button {
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1em;
  box-sizing: border-box;
  display: block;
  background-image: linear-gradient(180deg, #3CE2F2 0%, #1CC1E0 100%);
  -ms-box-shadow: 0 10px 25px 0 #51284F;
  box-shadow: 0 10px 25px 0 #51284F;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  margin: 0 auto;
  padding: 20px 55px;
}
.button:hover,
.button:focus {
  outline: none;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -ms-box-shadow: 0 0 0 0 #51284F;
  box-shadow: 0 0 0 0 #51284F;
}
.button--small {
  padding: 20px !important;
}
.button--large {
  display: block;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 700;
  font-size: normal;
  font-display: swap;
  font-size: 1.75rem;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  padding: 15px 2% 35px 2%;
  height: 100px;
  width: 100%;
  max-width: 350px;
  background-image: linear-gradient(180deg, #3CE2F2 0%, #1CC1E0 100%);
  border-radius: 20px;
  -ms-box-shadow: 0 4px 8px 0 #51284F;
  box-shadow: 0 4px 8px 0 #51284F;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 45px;
  margin: auto;
}
.button--large:hover,
.button--large:focus,
.button--large:active {
  outline: none;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  -ms-box-shadow: 0 0 0 0 #51284F;
  box-shadow: 0 0 0 0 #51284F;
}
.button--large-hide {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}
@media screen and (max-width: 667px) {
  .button--large {
    font-size: 1.5rem;
    padding: 10px 2% 20px 2%;
    max-width: 350px;
    height: 80px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .button--large {
    bottom: -20px;
  }
}
@media only screen and (max-width: 500px) {
  .button--large {
    bottom: -20px;
  }
}
/* Dropdown/Select */
.dropdown {
  -webkit-appearance: none;
  /*Safari/Chrome*/
  /*Firefox*/
  appearance: none;
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 60px;
  width: 100%;
  padding: 0 15px 0 50px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  background-image: url(/app/themes/upmc-na/static/media/icon_downarrow.c86c1c11.svg);
  background-position: 97% center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.dropdown:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
select {
  -webkit-appearance: none;
  /*Safari/Chrome*/
  /*Firefox*/
  appearance: none;
  background: #fff;
  border-radius: 4px;
  border: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1rem;
  color: #666D70;
  height: 60px;
  width: 100%;
  padding: 0 15px 0 50px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 20px auto;
  background-image: url(/app/themes/upmc-na/static/media/icon_downarrow.c86c1c11.svg);
  background-position: 97% center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  transition: box-shadow 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
select:focus {
  outline: none;
  -ms-box-shadow: 0 0 20px 0 #BEB4E3;
  box-shadow: 0 0 20px 0 #BEB4E3;
}
/* Checkboxes */
.checkboxGroup {
  display: block;
  /* IE < 10, Opera *Presto* Desktop (Now dead) */
  /* Safari 3.1 - 6, Chrome < 21 (2009 Spec), UCBrowser Android */
  /* Firefox 2 - 27 (2009 Spec), UCMini Android */
  /* IE10 (2012 Syntax) */
  /* Safari 6.1 - 8, Android < 4.4, BB < 10, Chrome 21 - 28 */
  display: flex;
  /* Edge 12+, Firefox 28+, Blink, Safari 9+, Opera Mini 8+ */
  position: relative;
}
.checkboxGroup--large {
  flex-flow: column nowrap;
  width: 160px;
}
.checkboxGroup--small {
  flex-flow: row nowrap;
  margin: 0 0 15px 0;
}
input[type=checkbox] {
  -webkit-appearance: none;
  /*Safari/Chrome*/
  /*Firefox*/
  appearance: none;
}
.checkbox {
  cursor: pointer;
  background: url(/app/themes/upmc-na/static/media/icon_check_unchecked.e9ed1d5f.svg) center center no-repeat;
  background-size: 100% 100%;
  border: none;
  transition: -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.checkbox:active,
.checkbox:focus,
.checkbox:hover {
  -webkit-transform: scale(0.95, 0.95);
  transform: scale(0.95, 0.95);
}
.checkbox--large {
  width: 120px;
  height: 120px;
  display: block;
  margin: 0 auto 15px auto;
}
.checkbox--small {
  width: 60px;
  height: 60px;
  display: inline-block;
}
.checkbox--green:checked {
  background-image: url(/app/themes/upmc-na/static/media/icon_check_green.43cc7d69.svg);
}
.checkbox--blue:checked {
  background-image: url(/app/themes/upmc-na/static/media/icon_check_blue.9ad59d46.svg);
}
@media screen and (max-width: 667px) {
  .checkbox--large {
    width: 100px;
    height: 100px;
  }
}
.label__checkbox {
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1.25em;
  color: #51284F;
}
.label__checkbox--large {
  display: block;
  width: 100%;
  text-align: center;
}
.label__checkbox--small {
  display: inline-block;
  -ms-align-self: center;
  align-self: center;
  margin: 0 0 0 15px;
}
@media screen and (max-width: 667px) {
  .label__checkbox {
    font-size: 1em;
  }
}
/* Validation Feedback */
.validation__message {
  display: block;
  width: 100%;
  max-width: 400px;
  height: 100%;
  margin: 0 auto 15px auto;
  text-align: center;
  color: #FF0000;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 700;
  font-size: normal;
  font-display: swap;
  transition: opacity 250ms cubic-bezier(0, 0.615, 0.39, 1), max-height 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.validation__message a {
  color: #fff;
  text-decoration: underline;
}
.validation__message a:hover {
  text-decoration: none;
}
.validation__message--show {
  max-height: 30px;
}
.validation__message--hide {
  max-height: 0;
}

.header {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  transition: -webkit-transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 550ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
}
.header--show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.header--hide {
  -webkit-transform: translateY(-150px);
  transform: translateY(-150px);
}
.header__logout {
  cursor: pointer;
  -webkit-appearance: none;
  /*Safari/Chrome*/
  /*Firefox*/
  appearance: none;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 900;
  font-size: normal;
  font-display: swap;
  font-size: 0.9rem;
  color: #666D70;
  background: url(/app/themes/upmc-na/static/media/icon_logout.6ae33977.svg) left center no-repeat;
  background-size: 20px 20px;
  border: none;
  padding: 15px;
  margin: 15px;
  transition: -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 250ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 250ms cubic-bezier(0, 0.615, 0.39, 1);
  position: absolute;
  top: 0;
  left: 0;
}
.header__logout span {
  padding: 0 0 0 10px;
}
.header__logout:hover {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}
.header__user {
  display: block;
  margin: 0 auto;
  padding: 10px 0 0 0;
}
.header__user_name {
  display: block;
  text-align: center;
  margin: 0;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 500;
  font-size: normal;
  font-display: swap;
  font-size: 1.45rem;
  color: #771B61;
}
.header__user_avatar {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100px;
  border: 5px solid #fff;
}
.header__user_image {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 650px) {
  .header {
    height: 70px;
  }
  .header__logout {
    margin: 10px;
  }
  .header__user_name {
    font-size: 1.2rem;
  }
  .header__user_avatar {
    width: 60px;
    height: 60px;
  }
}

.breadcrumbs {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #51284F;
  padding: 20px;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  -ms-align-content: flex-start;
  align-content: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  display: block;
  /* IE < 10, Opera *Presto* Desktop (Now dead) */
  /* Safari 3.1 - 6, Chrome < 21 (2009 Spec), UCBrowser Android */
  /* Firefox 2 - 27 (2009 Spec), UCMini Android */
  /* IE10 (2012 Syntax) */
  /* Safari 6.1 - 8, Android < 4.4, BB < 10, Chrome 21 - 28 */
  display: flex;
  /* Edge 12+, Firefox 28+, Blink, Safari 9+, Opera Mini 8+ */
  transition: -webkit-transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
  transition: transform 550ms cubic-bezier(0, 0.615, 0.39, 1), -webkit-transform 550ms cubic-bezier(0, 0.615, 0.39, 1);
}
.breadcrumbs--show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.breadcrumbs--hide {
  -webkit-transform: translateY(150px);
  transform: translateY(150px);
}
.breadcrumbs__crumb {
  padding: 0 15px;
  margin: 0 10px 0 0;
  border-radius: 5px;
  font-family: 'museo-sans', Arial, sans-serif;
  font-weight: 700;
  font-size: normal;
  font-display: swap;
  font-size: 1em;
  line-height: 40px;
  background: #51284F;
  color: #fff;
  text-align: center;
  border: 2px solid #1CC1E0;
  transition: all 250ms cubic-bezier(0, 0.615, 0.39, 1);
}
.breadcrumbs__crumb:hover {
  background: #fff;
  border: 2px solid #fff;
  color: #1CC1E0;
}
.breadcrumbs__crumb:active {
  background: #fff;
  border: 2px solid #fff;
  color: #1CC1E0;
}
.breadcrumbs__crumb:last-child {
  background: #fff;
  border: 2px solid #fff;
  color: #1CC1E0;
}
@media only screen and (max-width: 667px) {
  .breadcrumbs {
    padding: 10px;
  }
  .breadcrumbs__crumb {
    padding: 0 10px;
    margin: 0 10px 0 0;
    font-size: 0.8em;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  .breadcrumbs {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .breadcrumbs {
    display: none;
  }
}

